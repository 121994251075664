const createViewportObserver = (cb, element) => {
  const options = {
    root: null, // for viewport intersection
    rootMargin: "10px",
    threshold: [0.05, 0.1, 0.2, 0.3],
  }

  let observer = new IntersectionObserver(cb, options)
  observer.observe(element)
}

export default createViewportObserver
