import React from "react"
import "./blog.css"
import { blogsVideoLinks } from "../../../../site_data/aboutpage.json"

const BlogEmbedded = () => {
  return (
    <div className="blog-section">
      <p>Meet The Talking Hedge!</p>
      <div className="blog-video-container">
        {blogsVideoLinks.map(url => {
          return (
            <iframe
              key={url}
              width="300"
              height="200"
              src={url}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          )
        })}
      </div>
    </div>
  )
}

export default BlogEmbedded
