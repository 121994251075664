import React from "react"
import { BrowserRouter } from "react-router-dom"
import AISection1 from "../components/about/aiSection1"
import BlogSection4 from "../components/about/blogSection4"
import OurVisionSection2 from "../components/about/ourVisionSection2"
import SftToolSection3 from "../components/about/sftToolSection3"
import FooterNew from "../components/footer/FooterNew"
import Header from "../components/header/Header"

const about = () => {
  return (
    <div className="body-container_1">
      <Header />
      <AISection1 />
      <OurVisionSection2 />
      <SftToolSection3 />
      <BlogSection4 />
      <FooterNew />
    </div>
  )
}

export default about
