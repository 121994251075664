import React, { useRef, useEffect, createRef } from "react"
import "./blogSection4.css"
import BlogEmbedded from "./blogs/blogs"
import createViewportObserver from "../../components/ui/animations/intersectionObserver"
import "../../components/ui/animations/glide_in.css"
import { navigate } from "gatsby"

const BlogSection4 = () => {
  const cardsRef = useRef(
    Array(1)
      .fill(0)
      .map(x => createRef())
  )
  const cb = (entries, observer) => {
    // console.log(entries)
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add("bounce-box-push-screen")
      }
      // we can also append this if we want to iterate the flow again
    })
  }
  useEffect(() => {
    cardsRef.current.forEach(card => {
      card.current.classList.add("bounce-box-off-screen")
      createViewportObserver(cb, card.current)
    })
  }, [])
  return (
    <div ref={cardsRef.current[0]} className="blogSection4_container">
      <BlogEmbedded />
      <p>
        C3 Fund, LP would invest by building strategies using AI based tools &
        technical analysis to invest in the 3 most futuristic industries -
        Cannabis, Chips (Semiconductor) & Chinese industries
      </p>
      <button onClick={() => navigate("/contact")} className="c3_solid_btn">
        Contact Us
      </button>
    </div>
  )
}

export default BlogSection4
