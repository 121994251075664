import React, { useEffect, useRef, createRef } from "react"
import aiIcon from "../../assets/icons/ai_icon.png"
import "./aiSection1.css"
import "../ui/animations/glowBorder.css"
import createViewportObserver from "../../components/ui/animations/intersectionObserver"
import "../../components/ui/animations/glide_in.css"
import { navigate } from "gatsby"

const AISection1 = () => {
  const cardsRef = useRef(
    Array(2)
      .fill(0)
      .map(x => createRef())
  )
  const cb = (entries, observer) => {
    // console.log(entries)
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add("bounce-box-push-screen")
      }
      // we can also append this if we want to iterate the flow again
    })
  }
  useEffect(() => {
    cardsRef.current.forEach(card => {
      card.current.classList.add("bounce-box-off-screen")
      createViewportObserver(cb, card.current)
    })
  }, [])
  return (
    <div className="aiSection1_container">
      <div ref={cardsRef.current[0]} className="aiSection1_container_left">
        <div className="aiSection1_container_left_content">
          <h3>
            Add AI + ML's competitive
            <br />
            advantage to your
            <br />
            investment toolbox
          </h3>
          <p>
            Invest using automated algorithmic trading for capital preservation,
            risk management and long-term growth.
          </p>
          <button onClick={() => navigate("/contact")} className="c3_solid_btn">
            Book a Demo
          </button>
        </div>
      </div>
      <div ref={cardsRef.current[1]} className="aiSection1_container_right">
        <div className="glow_box glowing">
          <div className="glow_box_content">
            <img
              style={{ width: "100%", height: "100%" }}
              src={aiIcon}
              alt="ai_icon"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AISection1
