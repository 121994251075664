import React, { useEffect, useRef, createRef } from "react"
import "./sftToolSection3.css"
import sftToolSection3 from "../../assets/about/sftToolSection3.svg"
import createViewportObserver from "../../components/ui/animations/intersectionObserver"
import "../../components/ui/animations/glide_in.css"
const SftToolSection3 = () => {
  const cardsRef = useRef(
    Array(2)
      .fill(0)
      .map(x => createRef())
  )
  const cb = (entries, observer) => {
    // console.log(entries)
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add("bounce-box-push-screen")
      }
      // we can also append this if we want to iterate the flow again
    })
  }
  useEffect(() => {
    cardsRef.current.forEach(card => {
      card.current.classList.add("bounce-box-off-screen")
      createViewportObserver(cb, card.current)
    })
  }, [])
  return (
    <div className="sftToolSection3_container">
      <div ref={cardsRef.current[0]} className="sftToolSection3_container_left">
        <h3>SFT Tool</h3>
        <p>
          Smart Frequency Trading is the proprietary AI- based tool that analyze
          multiple data points and creates potentials trades using automated
          alogrithmic trading. <br />
          <br />
          The core of the engine uses the combination of technical analysis,
          quantization analysis, predictive analytics & cognitive analysis.
        </p>
      </div>
      <div
        ref={cardsRef.current[1]}
        className="sftToolSection3_container_right"
      >
        <img src={sftToolSection3} alt="HLIC" />
      </div>
    </div>
  )
}

export default SftToolSection3
