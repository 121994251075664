import React, { useEffect, useRef, createRef } from "react"
import "./ourVisionSection2.css"
import RohitJoshHalfLength from "../../assets/about/rohit_josh_half_length.png"
import createViewportObserver from "../../components/ui/animations/intersectionObserver"
import "../../components/ui/animations/glide_in.css"

const OurVisionSection2 = () => {
  const cardsRef = useRef(
    Array(2)
      .fill(0)
      .map(x => createRef())
  )
  const cb = (entries, observer) => {
    // console.log(entries)
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add("bounce-box-push-screen")
      }
      // we can also append this if we want to iterate the flow again
    })
  }
  useEffect(() => {
    cardsRef.current.forEach(card => {
      card.current.classList.add("bounce-box-off-screen")
      createViewportObserver(cb, card.current)
    })
  }, [])
  return (
    <div className="ourVisionSection2_container">
      <div
        ref={cardsRef.current[0]}
        className="ourVisionSection2_container_left"
      >
        <h3>Our Vision</h3>
        <img src={RohitJoshHalfLength} alt="HLIC" />
      </div>
      <div
        ref={cardsRef.current[1]}
        className="ourVisionSection2_container_right"
      >
        <h3>Our Vision</h3>
        <p>
          At C3 Fund, LP, our vision is to build a brand that becomes one of the
          top choices of investment for accredited investors and eventually
          convert it into a trad-able index fund. Our investors' best interest
          would always come first, and everything we do is guided by our values,
          professional ethics & Technology. Using AI to make decisions would be
          the core model of our business.
        </p>
      </div>
    </div>
  )
}

export default OurVisionSection2
